import { BlocksContent, Button, Drawer, ModalBox } from "@global";
import {
  CourseProgressionWidget,
  DashboardAccount,
  DashboardContact,
  ModalVideo,
  Sidebar,
  WorkshopWidget,
} from "@components";
import { Container, DesktopContainer } from "@util/standard";
import { PageProps, Script, graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { assets, colors, mediaQuery } from "@util/constants";

import Header from "@shared/header";
import { Query } from "@graphql-types";
import { isBrowser } from "@util/helper";
import styled from "styled-components";
import { useCheckScreenSize } from "@util/hooks";
import { useReturnCompleted } from "@state/logicHooks";
import { useStore } from "@state/store";
import { checkCookie, logoutUser } from "@auth/getFirebase";
import { toPlainText } from "@portabletext/react";

import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { useFirebaseUpdateUser } from "@util/useFirestoreDocFuncs";
import { useCheckWillPowerScore } from "@util/hooks/willpowerHooks";

interface Props extends PageProps {
  pageContext: any;
  data: Query;
}

const StyledDashboard = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: ${colors.dashboardBackground};
  padding: 20px 20px 0px 20px;

  .greeting {
    font-size: 40px;
    color: ${colors.maroon};
    margin-top: 20px;
  }

  .content {
    background-color: ${colors.dashboardBackground};
    padding: 20px;
  }

  .hamburger {
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    z-index: 100;
    top: 10px;
    left: 10px;
  }

  ${mediaQuery.tabletDown} {
    grid-template-columns: 1fr;

    .content {
      padding: 0px;
    }
  }

  .hamburger-wrapper {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    z-index: 100;
    background-color: white;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    img {
      width: 25px;
      margin: auto;
      transform: rotate(90deg);
    }
  }
`;

const modalBaseStyle = {
  content: {
    top: "50%",
    left: "50%",
    width: "70%",
    height: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    overflowY: "scroll",
    background: "transparent",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
  },
};

export default function Dashboard({ pageContext, data }: Props) {
  const { workshops } = pageContext;
  const { user, setUser, setPopupVisible } = useStore();
  const [drawVisible, setDrawVisible] = useState(false);
  const [state, setState] = useState(0);
  const { mobileDown, tabletDown } = useCheckScreenSize();
  const [hasCompleted, setHasCompleted] = useState(false);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const { timerEnded, extensionCount, extendTimer, isExtendingTimer, timeLeft } =
    useCheckWillPowerScore();

  const shouldShowWillpowerModal = () => {
    if (user?.isAdmin) return false;
    if (timerEnded && timeLeft <= 0 && !isExtendingTimer) {
      return true;
    }

    return false;
  };

  const startTour = () => {
    if (user && !user.hasCompletedTour) {
      setRun(true);
    }
  };

  useEffect(() => {
    if (run) {
      setStepIndex(1);
    }
  }, [drawVisible]);

  const handleJoyrideCallback = data => {
    const { status, index, type, action } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type) && index == 0) {
      setRun(false);
      setDrawVisible(true);
      setStepIndex(1);
      setTimeout(() => {
        setRun(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index == 3) {
      setRun(false);

      setPopupVisible && setPopupVisible(true, "badges");
      setDrawVisible(false);
      setTimeout(() => {
        setRun(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index == 4) {
      setRun(false);
      setPopupVisible && setPopupVisible(false, "badges");
      setDrawVisible(true);
      setTimeout(() => {
        setRun(true);
      }, 700);
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index == 5) {
      setRun(false);
      if (action === ACTIONS.PREV) {
        setStepIndex(2);
        setRun(true);
        return;
      }
      if (drawVisible) {
        setDrawVisible(false);
        setStepIndex(4);
        setTimeout(() => {
          setRun(true);
        }, 700);
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    }

    if ([EVENTS.STEP_AFTER].includes(type) && index !== 0) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    if (status === "finished") {
      setUser({ ...user, hasCompletedTour: true });
      useFirebaseUpdateUser("users", user?.uid, {
        hasCompletedTour: true,
      });
    }
  };

  const steps =
    data?.sanityDashboardSettings &&
    data?.sanityDashboardSettings?.onboardingSteps?.map(step => {
      if (step?.content == null || step?.targetName == null) return;
      return {
        target: `#${step?.targetName}`,
        content: toPlainText(step?.content._rawBlockContent),
        disableBeacon: true,
      };
    });

  console.log({ steps });

  const { setAllSanityWorkshops, sortedCompletedWorkshops, workshopCompletedIndex } =
    useReturnCompleted();

  checkCookie();

  useEffect(() => {
    setAllSanityWorkshops(workshops);
  }, []);

  useEffect(() => {
    if (sortedCompletedWorkshops && sortedCompletedWorkshops.length === 9) {
      const last = sortedCompletedWorkshops[8];
      const hasCompleted = Boolean(last.completed == true);
      setHasCompleted(hasCompleted);
    }
  }, [sortedCompletedWorkshops]);

  if (!user) {
    if (isBrowser()) {
      return navigate("/");
    }
  }

  return (
    <>
      <Script
        id="sib"
        dangerouslySetInnerHTML={{
          __html: ` (function(d, w, c) {
        w.SibConversationsID = '63be052fd44be91a1b24e842';
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        var s = d.createElement('script');
        s.async = true;
        s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'SibConversations');`,
        }}
      />

      <StyledDashboard>
        {!mobileDown && (
          <DesktopContainer>
            <Sidebar sortedCompletedWorkshops={sortedCompletedWorkshops} setState={setState} />
          </DesktopContainer>
        )}
        <div className="content">
          <Header state={state} setState={setState} setRunTour={setRun} />
          {state === 0 && (
            <>
              {tabletDown && (
                <div className="hamburger-wrapper">
                  <img
                    src={assets.accordionActiveOrange}
                    onClick={() => {
                      setDrawVisible(true);
                    }}
                  />
                </div>
              )}
              <h2 className="greeting">
                {data.sanitySiteConfig?.defaultDashboardGreeting ?? "Welcome to Houston"},{" "}
                {user?.firstName ?? user?.displayName}{" "}
              </h2>
              <section>
                <CourseProgressionWidget sortedCompletedWorkshops={sortedCompletedWorkshops} />
              </section>
              <section>
                <WorkshopWidget
                  workshops={workshops}
                  sortedCompletedWorkshops={sortedCompletedWorkshops}
                  workshopCompletedIndex={workshopCompletedIndex}
                />
              </section>
            </>
          )}
          {state === 1 && <DashboardAccount setState={setState} />}
          {state === 2 && <DashboardContact data={data?.sanityPage} />}
        </div>
      </StyledDashboard>
      {tabletDown && (
        <Drawer visible={drawVisible} onClose={() => setDrawVisible(false)} placement="left">
          <Sidebar
            sortedCompletedWorkshops={sortedCompletedWorkshops}
            setState={setState}
            setDrawerVisible={setDrawVisible}
          />
        </Drawer>
      )}

      {user && (user.dashboardVideoWatched === false || user?.dashboardVideoWatched == null) && (
        <ModalVideo
          url={data.sanitySiteConfig?.dashboardTutorialVideo?.url}
          dashboardVideo
          autoPlayOnLoad={false}
          volume
          thumbnail={undefined}
          startTour={startTour}
        />
      )}
      {user &&
        (user.completionVideoWatched === false || user?.completionVideoWatched == null) &&
        hasCompleted && (
          <ModalVideo
            url={data.sanitySiteConfig?.dashboardCompletionVideo?.url}
            completionVideo
            autoPlayOnLoad={false}
            volume
            thumbnail={undefined}
          />
        )}

      <ModalBox visible={shouldShowWillpowerModal()} customStyle={modalBaseStyle}>
        <Container
          backgroundColor="yellow"
          width="100%"
          padding="100px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {extensionCount === 0 && (
            <BlocksContent data={data?.sanityDashboardSettings?.timerExtensionOne} />
          )}
          {extensionCount === 1 && (
            <BlocksContent data={data?.sanityDashboardSettings?.timerExtensionTwo} />
          )}
          {extensionCount === 2 && (
            <BlocksContent data={data?.sanityDashboardSettings?.timerExtensionThree} />
          )}
          {extensionCount === 3 && (
            <BlocksContent data={data?.sanityDashboardSettings?.timerExtensionFour} />
          )}
          {extensionCount === 4 && (
            <BlocksContent data={data?.sanityDashboardSettings?.timerExtensionFive} />
          )}
          {extensionCount === 5 && (
            <>
              <BlocksContent data={data?.sanityDashboardSettings?.useOutOfTime} />
              <button
                onClick={() => {
                  logoutUser();
                }}
              >
                Close
              </button>
            </>
          )}
          {extensionCount !== 5 && (
            <Button
              linkText="Get another 30 days"
              onClick={extendTimer}
              buttonTheme="orange"
              margin="50px 0 0 0"
            />
          )}
        </Container>
      </ModalBox>

      <Joyride
        steps={steps}
        run={run}
        hideCloseButton
        continuous
        locale={{
          next: "Got it",
          last: "Get started",
        }}
        floaterProps={{
          placement: "bottom",
        }}
        stepIndex={stepIndex}
        styles={{
          buttonNext: {
            backgroundColor: colors.orange,
          },
          buttonBack: {
            color: colors.primary,
          },
        }}
        callback={handleJoyrideCallback}
        debug
      />
    </>
  );
}

export const query = graphql`
  query dashboardQuery {
    sanityDashboardSettings {
      onboardingSteps {
        _key
        targetName
        content {
          ...sanityBlockContent
        }
      }
      timerExtensionOne {
        ...sanityBlockContent
      }
      timerExtensionTwo {
        ...sanityBlockContent
      }
      timerExtensionThree {
        ...sanityBlockContent
      }
      timerExtensionFour {
        ...sanityBlockContent
      }
      timerExtensionFive {
        ...sanityBlockContent
      }
      useOutOfTime {
        ...sanityBlockContent
      }
    }
    sanityPage(_id: { eq: "contactPage" }) {
      _id
      blocks {
        ...sanityColumnBlock
      }
      backgroundOptions
      backgroundColour {
        ...sanityColorlist
      }
      imageWithMeta {
        ...sanityImageFullWidthNoCompressionNoCompression
      }
    }
    sanitySiteConfig {
      dashboardTutorialVideo {
        url
      }
      dashboardCompletionVideo {
        url
      }
      defaultDashboardGreeting
    }
  }
`;
